import React, {FC, useEffect} from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import FieldTextControlled from 'components/shared/form/FieldTextControlled'
import FieldAutocomplete from 'components/shared/form/FieldAutocomplete'
import FieldNumber from 'components/shared/form/FieldNumber'
import Backdrop from 'components/shared/Backdrop'
import PlusIcon24 from 'components/shared/icons/24x24/PlusIcon24'
import { AddShell, AddShellValues, FetchShellsList, ShellsFGetStatusItem, ShellsTableHeadIds } from 'types/shells.types'
import { FetchFarmersList, FarmersList, FarmersItem } from 'types/materials.types'
import { SortMethod } from 'types/shared.types'
import { PATH_SHELLS } from 'constants/routes.constants'
import {
  SHELLS_CACHE_STATUS_OPTIONS,
  SHELLS_FGET_STATUS_OPTIONS,
  SHELLS_DEFAULT_PAGINATION_PER_PAGE
} from 'constants/shells.constants'
import schema from './AddShellForm.schemas'
import {DOMAINS_CREATE_VALUES_DEFAULTS} from "../../../../constants/domains.constants"

interface AddShellFormParams {
  addShell: AddShell
  fetchShellsList: FetchShellsList
  fetchFarmersList: FetchFarmersList
  farmersList: FarmersList
}

const AddShellForm: FC<AddShellFormParams> = ({ addShell, fetchShellsList, fetchFarmersList, farmersList }) => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const sortMethod = (searchParams.get('sortMethod') as SortMethod) || SortMethod.DESC
  const sortBy = (searchParams.get('sortBy') as ShellsTableHeadIds) || ShellsTableHeadIds.CREATED_AT
  const page = 1
  const perPage = Number(searchParams.get('perPage')) || SHELLS_DEFAULT_PAGINATION_PER_PAGE

  const { farmerId } = DOMAINS_CREATE_VALUES_DEFAULTS

  useEffect(() => {
    fetchFarmersList()
  }, [])

  useEffect(() => {
    if (farmersList.length === 1) {
      setValue('farmId', String(farmersList[0].id))
      return
    }

    if (farmersList.length) {
      setValue('farmId', String(farmersList.find(farmer => farmer.title === farmerId)?.id) || '')
    }
  }, [farmersList])

  const addShellValues: AddShellValues = {
    domain: '',
    cost: 10,
    farmId: null,
    hand_status: false,
    cachedStatus: false,
    filename: 'wp-stamp.php',
    system: 'WP',
    comments: '',
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    setValue
  } = useForm({
    defaultValues: addShellValues,
    mode: 'all',
    resolver: yupResolver(schema)
  })

  const fgetStatusState = watch('hand_status')

  const handleAddShell = async (values: AddShellValues) => {
    try {
      await addShell(values)

      handleFetchShellsList()
    } catch (error) {
      console.log(error)
    }
  }

  const handleFetchShellsList = () => {
    fetchShellsList(sortMethod, sortBy, page, perPage)

    navigate({
      pathname: PATH_SHELLS,
      search: createSearchParams({ sortMethod, sortBy, page: String(page), perPage: String(perPage) }).toString()
    })
  }

  return (
    <form onSubmit={handleSubmit(handleAddShell)}>
      <FieldTextControlled sx={{ mb: 2 }} label="FORM.FIELD.TITLE.DOMAIN" name="domain" control={control} />
      <FieldNumber
        sx={{ mb: 2 }}
        control={control}
        name="cost"
        label="FORM.FIELD.TITLE.COST"
        numberProps={{ decimalScale: 2 }}
      />

      <FieldAutocomplete
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.DOMAINS_SPAM"
          name="farmId"
          control={control}
          optionsList={farmersList}
          getValue={value => (value ? farmersList.find(option => Number(option.id) === Number(value)) : '')}
          getNewValue={(newValue: FarmersItem | null) => newValue?.id || null}
          getOptionLabel={option => (option ? intl.formatMessage({ id: option?.title }) : '')}
          renderOption={(props, { title }) => (
              <MenuItem {...props} key={title}>
                {title}
              </MenuItem>
          )}
      />

      <FieldAutocomplete
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.CACHE"
          name="cachedStatus"
          control={control}
          optionsList={SHELLS_CACHE_STATUS_OPTIONS}
          getValue={value => SHELLS_CACHE_STATUS_OPTIONS.find(option => option.value === value)}
          getNewValue={(newValue: ShellsFGetStatusItem | null) => newValue?.value || false}
          getOptionLabel={option => (option ? intl.formatMessage({ id: option?.title }) : '')}
          renderOption={(props, { title }) => (
              <MenuItem {...props} key={title}>
                {intl.formatMessage({ id: title })}
              </MenuItem>
          )}
      />
      <FieldAutocomplete
        sx={{ mb: 2 }}
        label="FORM.FIELD.TITLE.UPLOAD_METHOD"
        name="hand_status"
        control={control}
        optionsList={SHELLS_FGET_STATUS_OPTIONS}
        getValue={value => SHELLS_FGET_STATUS_OPTIONS.find(option => option.value === value)}
        getNewValue={(newValue: ShellsFGetStatusItem | null) => newValue?.value || false}
        getOptionLabel={option => (option ? intl.formatMessage({ id: option?.title }) : '')}
        renderOption={(props, { title }) => (
          <MenuItem {...props} key={title}>
            {intl.formatMessage({ id: title })}
          </MenuItem>
        )}
      />

      <FieldTextControlled sx={{ mb: 2 }} label="FORM.FIELD.TITLE.FILENAME" name="filename" control={control} disabled={fgetStatusState} />

      <FieldTextControlled sx={{ mb: 2 }} label="FORM.FIELD.TITLE.SYSTEM" name="system" control={control} />

      <FieldTextControlled sx={{ mb: 2 }} label="FORM.FIELD.TITLE.COMMENTS" name="comments" control={control} />

      <Box display="flex" justifyContent="center" mt={4}>
        <Button type="submit" variant="contained" size="large" fullWidth startIcon={<PlusIcon24 />}>
          {intl.formatMessage({ id: 'SHELLS_PAGE.BUTTON.ADD_SHELL' })}
        </Button>
      </Box>
      <Backdrop open={isSubmitting} />
    </form>
  )
}

export default AddShellForm
