import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import * as TOKENS from 'constants/tokens'

const Access1Icon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
      <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5 1C5.567 1 4 2.567 4 4.5H6C6 3.67157 6.67157 3 7.5 3H8.33333C9.25381 3 10 3.74619 10 4.66667V8H3C2.44772 8 2 8.44772 2 9V14C2 14.5523 2.44772 15 3 15H13C13.5523 15 14 14.5523 14 14V9C14 8.44772 13.5523 8 13 8H12V4.66667C12 2.64162 10.3584 1 8.33333 1H7.5Z"
            fill="#A1A1AA"/>

      </SvgIcon>
  )
}

export default Access1Icon16
