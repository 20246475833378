import { connect } from 'react-redux'
import { addShell, fetchShellsList } from 'modules/shells'
import AddShellForm from './AddShellForm'
import {RootState} from "types/system.types"
import {fetchFarmersList, getFarmersList} from "modules/materials"

const mapStateToProps = (state: RootState) => {
  return {
    farmersList: getFarmersList(state)
  }
}


const mapDispatchToProps = {
  addShell,
  fetchShellsList,
  fetchFarmersList
}
export default connect(mapStateToProps, mapDispatchToProps)(AddShellForm)
