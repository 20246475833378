import { Pagination, PaginationPayload, SortMethod } from './shared.types'

export enum ShellsActionType {
  FETCH_SHELLS_LIST = 'FETCH_SHELLS_LIST',
  SET_SHELLS_LIST = 'SET_SHELLS_LIST',
  ADD_SHELL = 'ADD_SHELL',
  UPDATE_SHELL = 'UPDATE_SHELL',
  SET_SHELL = 'SET_SHELL'
}

export interface ShellsState {
  shellsList: ShellsList
  shellsListPagination: Pagination
}

export enum ShellsTableHeadIds {
  CREATED_AT = 'created_at',
  ID = 'id',
  COST = 'cost',
  FARM_ID = 'farm_id',
  SYSTEM = 'system',
  COMMENTS = 'comments',
  FILENAME = 'filename',
  DOMAIN = 'domain',
  METHOD = 'method',
  FILE_STATUS = 'file_status',
  ACCESS_STATUS = 'access_status',
  HAND_STATUS = 'hand_status',
  CACHED_STATUS = 'cached_status',
  REQUESTS_TODAY = 'requests_today',
  REQUESTS_YESTERDAY = 'requests_yesterday'
}

export interface ShellsTableHeadCell {
  id: ShellsTableHeadIds
  label: string
  sortable?: boolean
}

export type ShellsTableHead = ShellsTableHeadCell[]

export type FetchShellsList = (
  sortMethod: SortMethod,
  sortBy: ShellsTableHeadIds,
  page: number,
  perPage: number
) => void

export interface FetchShellsListPayload extends PaginationPayload {
  items: ShellsListPayload
}

export interface ShellsItemPayload {
  id: number
  farm_id: number
  user_id: number
  domain: string
  cost: number
  ip: string
  system: string
  filename: string | null
  access_status: boolean
  comments: string
  file_status: boolean
  hand_status: boolean
  cached_status: boolean
  status: boolean
  requests_today: number
  requests_yesterday: number
  created_at: Date
  last_requested_at: Date
}

export type ShellsListPayload = ShellsItemPayload[]

export interface ShellsItem {
  createdAt: Date
  id: number
  farmId: number
  cost: number
  domain: string
  system: string
  accessStatus: boolean
  handStatus: boolean
  filename: string | null
  cachedStatus: boolean
  requestsToday: number
  requestsYesterday: number
  fileStatus: boolean
  lastRequestedAt: Date,
  comments: string
}

export type ShellsList = ShellsItem[]

export interface SetShellsListAction {
  type: ShellsActionType.SET_SHELLS_LIST
  payload: FetchShellsListPayload
}

export enum ShellsHash {
  ADD = '#add'
}

export interface AddShellValues {
  domain: string
  cost: number
  farmId: number | null
  hand_status: boolean
  cachedStatus: boolean
  filename: string
  system: string
  comments: string
}

export type AddShell = (data: AddShellValues) => void

export interface UpdateShellValues {
  status?: boolean
  fileStatus?: boolean
}

export type UpdateShell = (data: UpdateShellValues, shellId: number) => void

export interface SetShellAction {
  type: ShellsActionType.SET_SHELL
  payload: { data: ShellsItemPayload; shellId: number }
}

export interface ShellsFGetStatusItem {
  value: boolean
  title: string
}
