import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import * as TOKENS from 'constants/tokens'

const Access0Icon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 1C5.79086 1 4 2.79086 4 5V8H3C2.44772 8 2 8.44772 2 9V14C2 14.5523 2.44772 15 3 15H13C13.5523 15 14 14.5523 14 14V9C14 8.44772 13.5523 8 13 8H12V5C12 2.79086 10.2091 1 8 1ZM10 8V5C10 3.89543 9.10457 3 8 3C6.89543 3 6 3.89543 6 5V8H10Z"
          fill="#EF4444"/>
    </SvgIcon>
  )
}

export default Access0Icon16
